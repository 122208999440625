$mobile-width: 750px;

body, html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #041024;
}

:global {
  #app {
    height: 100%;
  }
}

.root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.mainWrap {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: grid;
  box-sizing: border-box;

  @media only screen and (max-width: $mobile-width) {
    padding: 44px 16px 20px;
    grid-template-rows: auto 1fr;
    grid-row-gap: 30px;
  }
}

.chuckWrap {
  @media only screen and (max-width: $mobile-width) {
    position: relative;
    width: 100%;
  }
}

.chuckImg {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 100%;
  z-index: 1;
  image-rendering: pixelated;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $mobile-width) {
    bottom: 0;
    top: auto;
    height: 70%;
  }

  img {
    height: 100%;
    pointer-events: none;
  }

  .chuckActivator {
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    z-index: 1;
  }
}

.stars {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  object-fit: cover;
  pointer-events: none;

  @media only screen and (max-width: $mobile-width) {
    height: 70%;
  }
}

.phrase {
  font-family: 'minecraft', Arial, sans-serif;
  z-index: 2;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  display: block;
  position: absolute;
  width: 100%;
  bottom: 140px;
  animation: fadeIn 1000ms linear;

  @media only screen and (max-width: $mobile-width) {
    min-height: 120px;
    bottom: auto;
    top: 44px;
    padding: 0 16px;
    width: calc(100% - 32px);
  }
}

.note {
  font-family: 'minecraft', Arial, sans-serif;
  z-index: 2;
  text-align: center;
  max-width: 760px;
  color: #AACAFF;
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
  display: block;
  position: fixed;
  bottom: 36px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: $mobile-width) {
    font-size: 16px;
  }

  &.animated {
    animation: blink 1000ms linear infinite;
  }

  span {
    transition: opacity .3s linear;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}