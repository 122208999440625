$font-base-url: './';

body {
  font-family: 'SB Sans Text', sans-serif;
}

@font-face {
  font-family: 'minecraft';
  src: url("#{$font-base-url}F77_Minecraft.woff") format('woff'), url('#{$font-base-url}F77_Minecraft.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}